import {Component, ElementRef, OnInit} from '@angular/core';
import {StaffResponse} from '../../Modules/admin/interfaces/artist-interface';
import {ArtistService} from '../../Modules/admin/services/artist.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-staf',
  templateUrl: './staf.component.html',
  styleUrls: ['./staf.component.css']
})
export class StafComponent implements OnInit {
  stafflist: string;
  usersArray: StaffResponse[];
  response: any;

  public selectedLanguage = 'en';
  public head: string;
  token: any;

  constructor(private aboutservice: ArtistService, private element: ElementRef,
  private translate: TranslateService) {
    this.usersArray = []
    this.aboutservice.getToken().subscribe(algo => {
      this.token = algo.access_token;
      this.aboutservice.getStaff(this.token).subscribe(data => {
        this.response = data;
        this.response.forEach(element => {
          this.usersArray.push({
            nombre: element.nombre, cargo: element.cargo, primera_img: element.primera_img, facebook: element.facebook,
            twitter: element.twitter, spotify: element.spotify, applemusic: element.spotify, instagram: element.instagram,
            direccion_web: element.direccion_web, youtubechannel: element.youtubechannel
          })
        })
      });
    });
  }
  ngOnInit(): void {
    this.selectedLanguage = localStorage.getItem('lang');
    this.translate.get('home.head').subscribe(res => this.head = res);

    if (localStorage.getItem('lang') == null){
      localStorage.setItem('lang','en')
    }

    if (localStorage.getItem('lang') != null){
      this.translate.setDefaultLang(localStorage.getItem('lang'));
    } else {
      localStorage.setItem('lang',this.selectedLanguage)
      this.translate.setDefaultLang("en");
    }
    this.selectedLanguage = localStorage.getItem('lang');
  }
}
