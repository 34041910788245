import {Component, OnInit, ElementRef, ViewChild} from '@angular/core';
import {Location} from '@angular/common';
import {NavbarComponent} from './shared/navbar/navbar.component';
import {TranslateService} from '@ngx-translate/core';
import {Meta, Title} from '@angular/platform-browser';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    public preloader =  document.getElementById('preloader')

    public selectedLanguage = 'en';
    public head: string;
    // isLoading: boolean = true;

    @ViewChild(NavbarComponent) navbar: NavbarComponent;

    constructor(private element: ElementRef, public location: Location, private translate: TranslateService, private titleService: Title,
                private metaTagService: Meta  ) {
    }

    ngOnInit() {
            // window.addEventListener('load', () => {
            //     document.getElementById('preloader').remove()
            // });
        this.titleService.setTitle('PlanetRecords');

        this.metaTagService.addTags([
            { name: 'keywords', content: 'salsa,bachata,reggaeton,musica,latina,musica latina,salsa cd,bachata cd,reggaeton cd,cd musica latina,musica latina cd,cd latini,latin cd,dvd salsa,dvd bachata,scuola di ballo,scuole di ballo,balli latini,ballo latino,ballo,balli,balli caraibici,ballo caraibico,ballo di gruppo,balli di gruppo,ballare, merengue,gratis,free,mp3,mp3 gratis,free mp3 download,free salsa music,musica salsa gratis,free salsa video,salsa video gratis,free bachata music,musica bachata gratis,free reggaeton music,musica reggaeton gratis,musica latina gratis,free latin music,salsa cubana,salsa puertoricana,salsa portoricana,new york style,los angeles,style,latin dance,latin music,tropical,tropical music,caribbean,caribe,musica caribena,reggeton,reggeaton,dance school,salsa school,escuela de baile,escuela de salsa,escuela de bachata,baile, cubana,cubano,puertoricana,portoricano,portoricana,dominicano,dominicana,bachata dominicana,locali salsa,locali bachata,locali latini,locale latino,locale latinoamericano,locale latino americano, locali latinoamericani,locali latino americani,bachatango,tango,latin,latino,latina,macarena,meneaito,meneito,rumba,mambo,cha cha cha,zumba,fitness,timba,son, santeria,yoruba,concerto,concerti,latinamericano,latino americano,latinoamericano,latinamericana,latino americana,latinoamericana,latinoamerica,america latina,sudamerica,sudamericano,sudamericana,caraibi,cuba,santo domingo,republica dominicana,portorico,puerto rico,colombia,venezuela,perù,peruviano,peruviana,forum assago,planet records,latinmusicstore,latinoamericando,fiesta,festival' },
            { name: 'writer', content: 'PlanetRecords' },
            { charset: 'UTF-8' }
        ]);
        this.selectedLanguage = localStorage.getItem('lang');
        this.translate.get('home.head').subscribe(res => this.head = res);

        if (localStorage.getItem('lang') == null) {
            localStorage.setItem('lang','En')
        }

        if (localStorage.getItem('lang') != null) {
            this.translate.setDefaultLang(localStorage.getItem('lang'));
        } else {
            localStorage.setItem('lang',this.selectedLanguage)
            this.translate.setDefaultLang("En");
        }
        this.selectedLanguage = localStorage.getItem('lang');

        // setTimeout(() => {
        //     this.isLoading = false;
        // }, 3500);
    }

    removeFooter() {
        let titlee = this.location.prepareExternalUrl(this.location.path());
        titlee = titlee.slice(1);
        return titlee !== '/profile';
    }
}
