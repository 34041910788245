import {Component, ElementRef, OnInit} from '@angular/core';
import {NewResponse, StaffResponse} from '../../Modules/admin/interfaces/artist-interface';
import {ArtistService} from '../../Modules/admin/services/artist.service';
import {TranslateService} from '@ngx-translate/core';
import {InstagramDOMService} from '../../Modules/dashboard/instagram-dom.service';
import {NgbCarouselConfig, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
    selector: 'app-news',
    templateUrl: './news.component.html',
    styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {
    newArray: NewResponse[];
    response: any;
    public selectedLanguage = 'en';
    public head: string;
    token: any;
    constructor(private instagram: InstagramDOMService, private aboutservice: ArtistService, config: NgbCarouselConfig, private router: Router, private sanitizer: DomSanitizer) {
        this.newArray = []
        this.aboutservice.getToken().subscribe(algo => {
            this.token = algo.access_token;
            this.aboutservice.getNew(this.token).subscribe(data => {
                this.response = data;
                this.response.forEach(element => {
                    if (typeof element.url_instagram === 'string') {
                        this.newArray.push({
                            nombre: element.nombre,
                            url_instagram: this.sanitizer.bypassSecurityTrustResourceUrl(element.url_instagram)
                        })
                    }
                })
            });
        })

    }
    ngOnInit(): void {
    }
}
