// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  apiHost: 'https://api-dev.planetrecords.it/',
  token1: 'Iy1LBg0NtA2XwpMfBFhdwpyLUMQkXEFwi0J4DZRK',
  token2: 'eikkiAdbKOlvcWl8257q6l9YKARo4AHxWOAh6mKzS1iNxb7Dt4KynlvfPOZEL8L6FaM621HY6AZUPRFqGvXjcnOwNtpH0MkfF8ywKa02IYWMyiC3bRcqWoZH6nlaOstM',
};
