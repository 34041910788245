import {Component, OnInit, ElementRef} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {connectableObservableDescriptor} from 'rxjs/internal/observable/ConnectableObservable';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    public isCollapsed = true;
    public selectedLanguage = 'En';
    public head: string;
    private menuVisible: boolean;


    constructor(private element: ElementRef, private translate: TranslateService,
    ) {

    }

    ngOnInit() {
        this.selectedLanguage = localStorage.getItem('lang');
        this.translate.get('home.head').subscribe(res => this.head = res);

        if (localStorage.getItem('lang') == null) {
            localStorage.setItem('lang', 'En')
        }

        if (localStorage.getItem('lang') != null) {
            this.translate.setDefaultLang(localStorage.getItem('lang'));
        } else {
            localStorage.setItem('lang', this.selectedLanguage)
            this.translate.setDefaultLang('En');
        }
        this.selectedLanguage = localStorage.getItem('lang');
    }


    selectLanguage(lang: string) {
        localStorage.setItem('lang', lang)
        if (localStorage.getItem('lang') != null) {
            this.translate.setDefaultLang(localStorage.getItem('lang'));
        } else {
            this.translate.setDefaultLang('En');
        }
        this.selectedLanguage = localStorage.getItem('lang')
        window.location.reload();

    }

}
