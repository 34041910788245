import {Component, ElementRef, OnInit} from '@angular/core';
import { AboutResponse } from 'app/Modules/admin/interfaces/artist-interface';
import { ArtistService } from 'app/Modules/admin/services/artist.service';
import {TranslateService} from '@ngx-translate/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})

export class ProfileComponent implements OnInit {
    aboutlist: SafeResourceUrl;
    token: string;
    usersArray: AboutResponse[];

    public selectedLanguage = 'en';
    public head: string;

    valor: string;

    response: any;
    private index: Number;
    constructor(private aboutservice: ArtistService, private element: ElementRef, private sanitizer: DomSanitizer,
                private translate: TranslateService) {
        this.aboutservice.getToken().subscribe(algo => {
            this.token = algo.access_token;
        this.aboutservice.getAbout(this.token).subscribe(data => {
            this.response = data;
            this.valor = this.response[0].descripcion.replace(/font-size: 12pt;/g, ' ')
            this.valor = this.valor.replace(/font-family: Calibri, sans-serif;/g, ' ')
            this.aboutlist = this.sanitizer.bypassSecurityTrustHtml(this.valor)
         });
        })
        }
    ngOnInit(): void {
        this.selectedLanguage = localStorage.getItem('lang');
        this.translate.get('home.head').subscribe(res => this.head = res);

        if (localStorage.getItem('lang') == null){
            localStorage.setItem('lang','en')
        }

        if (localStorage.getItem('lang') != null){
            this.translate.setDefaultLang(localStorage.getItem('lang'));
        } else {
            localStorage.setItem('lang',this.selectedLanguage)
            this.translate.setDefaultLang("en");
        }
        this.selectedLanguage = localStorage.getItem('lang');
    }
}
