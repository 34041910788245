import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';
import { ProfileComponent } from './examples/profile/profile.component';
import { DashboardComponent } from './Modules/dashboard/dashboard.component';
import { ArtistComponent } from './Modules/admin/components/artist/artist.component';
import { VideosComponent } from './Modules/admin/components/videos/videos.component';
import { StafComponent } from './examples/staf/staf.component';
import {ShopComponent} from './Modules/admin/components/shop/shop.component';
import {NewsComponent} from './examples/news/news.component';
import {PremiosComponent} from './examples/premios/premios.component';


const routes: Routes = [
    { path: '', component: DashboardComponent, pathMatch: 'full' },
    { path: 'videos',        component: VideosComponent },
    { path: 'news',        component: NewsComponent },
    { path: 'artists',        component: ArtistComponent },
    { path: 'staff',     component: StafComponent },
    { path: 'profile',     component: ProfileComponent },
    { path: 'awards',     component: PremiosComponent },
    { path: 'subscribe',     component: ShopComponent },
    { path: '404', component: DashboardComponent },
    { path: '**', component: DashboardComponent }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
