import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {ArtistComponent} from './admin/components/artist/artist.component'
import {VideosComponent} from './admin/components/videos/videos.component'
import {TranslateModule} from '@ngx-translate/core';
import {FilterPipe} from './admin/components/artist/filter.pipe'
import {FilterPipeVideos} from './admin/components/videos/filtervideos.pipe';




@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        TranslateModule,
    ],
    declarations: [
        ArtistComponent,
        VideosComponent,
        FilterPipe,
        FilterPipeVideos
    ]
})
export class ModulesModule { }
