import {Component, ElementRef, OnInit} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {ArtistService} from '../../Modules/admin/services/artist.service';
import {PprivacidadResponse} from '../../Modules/admin/interfaces/artist-interface';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  artistArray: PprivacidadResponse[];
  year: number = new Date().getFullYear()

  public selectedLanguage = 'en';
  public head: string;
  artist: PprivacidadResponse;
  response: any;
  token: any;
  public cantidad: number;

  constructor(private modalService: NgbModal, private aboutservice: ArtistService, private element: ElementRef,
              private translate: TranslateService) {
    this.artistArray = []
    this.aboutservice.getToken().subscribe(algo => {
      this.token = algo.access_token;
      this.aboutservice.getPprivacidad(this.token).subscribe(data => {
        this.response = data;
        this.response.forEach( element => {
          this.artistArray.push({
            descripcion: element.descripcion
          })
        })
        this.artist = this.artistArray.pop()
      });
      this.aboutservice.getCantidadvistas(this.token).subscribe(prueba => {
        this.cantidad = prueba.cantidad
      })
    })
  }
  open(content) {
    this.modalService.open(content);
  }
  ngOnInit(): void {
    this.selectedLanguage = localStorage.getItem('lang');
    this.translate.get('home.head').subscribe(res => this.head = res);

    if (localStorage.getItem('lang') == null){
      localStorage.setItem('lang','en')
    }

    if (localStorage.getItem('lang') != null){
      this.translate.setDefaultLang(localStorage.getItem('lang'));
    }else{
      localStorage.setItem('lang',this.selectedLanguage)
      this.translate.setDefaultLang("en");
    }
    this.selectedLanguage = localStorage.getItem('lang');
  }


}
