import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ProfileComponent } from './profile/profile.component';
import { StafComponent } from './staf/staf.component';
import {TranslateModule} from '@ngx-translate/core';
import {NewsComponent} from './news/news.component';
import {PremiosComponent} from './premios/premios.component';
import {RouterModule} from '@angular/router';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        TranslateModule,
        RouterModule,
    ],
    declarations: [
        StafComponent,
        ProfileComponent,
        NewsComponent,
        PremiosComponent
    ]
})
export class ExamplesModule { }
