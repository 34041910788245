import {Pipe, PipeTransform} from '@angular/core';
import {ArtistResponse} from '../../interfaces/artist-interface';

@Pipe({
    name: 'filterartistpipe'
})
export class FilterPipe implements PipeTransform {
    transform(artists: ArtistResponse[], filterartist: string) {
        if (artists.length === 0 || filterartist === '') {
            return artists;
        } else {
            return artists.filter((artist) => {
               return artist.nombre_artistico.toLowerCase().includes(filterartist.toLowerCase())
            })
        }

    }
}
