import {Component, OnInit} from '@angular/core';
import {ArtistResponse} from '../../interfaces/artist-interface';
import {ArtistService} from '../../services/artist.service';
import {
    trigger,
    style,
    query,
    stagger,
    animate,
    transition
} from '@angular/animations';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
    selector: 'app-artist',
    templateUrl: './artist.component.html',
    styleUrls: ['./artist.component.css'],
    animations: [
        trigger('listAnimation', [
            transition('* <=> *', [
                query(':enter',
                    [style({ opacity: 0 }), stagger('300ms', animate('600ms ease-out', style({ opacity: 1 })))],
                    { optional: true }
                ),
                query(':leave',
                    animate('200ms', style({ opacity: 0 })),
                    { optional: true}
                )
            ])
        ])
    ]
})

export class ArtistComponent implements OnInit {

    stafflist: string;
    artistArray: ArtistResponse[];
    response: any;
    token: any;
    filterartist: string;
    valor: string;

    constructor(private modalService: NgbModal, private aboutservice: ArtistService, private sanitizer: DomSanitizer) {
        this.artistArray = []
        this.aboutservice.getToken().subscribe(algo => {
            this.token = algo.access_token;
            this.aboutservice.getArtists(this.token).subscribe(data => {
                this.response = data;
                this.response.forEach(element => {
                    this.valor = element.observaciones.replace(/font-size: 12pt;/g, ' ')
                    this.valor = this.valor.replace(/font-family: Calibri, sans-serif;/g, ' ')
                    this.artistArray.push({
                        nombre_artistico: element.nombre_artistico,
                        facebook: element.facebook,
                        twitter: element.twitter,
                        spotify: element.spotify,
                        direccion_web: element.direccion_web,
                        instagram: element.instagram,
                        primera_img: element.primera_img.replace('http', 'https'),
                        youtubechannel: element.youtubechannel,
                        applemusic: element.applemusic,
                        descripcion: this.sanitizer.bypassSecurityTrustHtml(this.valor)
                    })
                })
            });
        });
    }
    open(content) {

        this.modalService.open(content, {size: 'lg', centered: true});
    }
    ngOnInit(): void {
    }
}
