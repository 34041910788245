import {environment} from '../../../../environments/environment';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {
    ArtistResponse,
    AboutResponse,
    RedesSocialesResponse,
    StaffResponse,
    VideoResponse,
    CarruselResponse, PprivacidadResponse, Tokenresponse, SuscribeResponse, NewResponse, PremiosResponse, VistaResponse
} from '../interfaces/artist-interface';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ArtistService {
    private variable: any;
    private urltoken = environment.apiHost + 'en-us/o/token/';
    private siteUrlartist = '/artist_api/';
    private siteUrlartistDash = '/artist_dash_api/';
    private siteUrlabout = '/aboutus_api/';
    private siteUrlstaff_api = '/staff_api/';
    private siteUrlpremios_api = '/premio_api/';
    private siteUrlvideo_api = '/video_api/';
    private siteUrlvideoDash = '/video_dash_api/';
    private siteUrlnewDash = '/noticia_dash_api/';
    private siteUrlnew = '/noticia_api/';
    private siteUrvistas = '/vistas_api/';
    private siteUrlcarrusel_api = '/carrusel_api/';
    private siteUrlpprivacidad_api = '/pprivacidad_api/';
    private siteUrlsuscripcion_api = '/suscriptor_api/';
    private selectedLanguage = localStorage.getItem('lang');
    public valor: any;
    public valortoken: any;
    public data: any;

    constructor(private _http: HttpClient) {
        if (this.selectedLanguage === 'Es') {
            this.variable = environment.apiHost + 'es'
        }
        if (this.selectedLanguage === 'En') {
            this.variable = environment.apiHost + 'en-us'
        }
        if (this.selectedLanguage === 'It') {
            this.variable = environment.apiHost + 'it'
        }
    }

    getArtists(token): Observable<ArtistResponse[]> {
        this.valortoken = new HttpHeaders({
            'Authorization': 'Bearer ' + token
        });
        return this._http.get<ArtistResponse[]>(this.variable + this.siteUrlartist, {headers: this.valortoken});
    }

    getArtistsDashboard(token): Observable<ArtistResponse[]> {
        this.valortoken = new HttpHeaders({
            'Authorization': 'Bearer ' + token
        });
        return this._http.get<ArtistResponse[]>(this.variable + this.siteUrlartistDash, {headers: this.valortoken});
    }

    getToken(): Observable<Tokenresponse> {
        this.valortoken = new HttpHeaders({
            'Authorization': 'Basic ' + btoa(environment.token1 + ':' + environment.token2)
        });
        const body: HttpParams = new HttpParams().append('grant_type', 'client_credentials');
        return this._http.post<Tokenresponse>(this.urltoken, body, {headers: this.valortoken});
    }

    getAbout(token): Observable<AboutResponse[]> {
        this.valortoken = new HttpHeaders({
            'Authorization': 'Bearer ' + token
        });
        return this._http.get<AboutResponse[]>(this.variable + this.siteUrlabout, {headers: this.valortoken});
    }

    getStaff(token): Observable<StaffResponse[]> {
        this.valortoken = new HttpHeaders({
            'Authorization': 'Bearer ' + token
        });
        return this._http.get<StaffResponse[]>(this.variable + this.siteUrlstaff_api, {headers: this.valortoken});
    }

    getPremios(token): Observable<PremiosResponse[]> {
        this.valortoken = new HttpHeaders({
            'Authorization': 'Bearer ' + token
        });
        return this._http.get<PremiosResponse[]>(this.variable + this.siteUrlpremios_api, {headers: this.valortoken});
    }

    getVideo(token): Observable<VideoResponse[]> {
        this.valortoken = new HttpHeaders({
            'Authorization': 'Bearer ' + token
        });
        return this._http.get<VideoResponse[]>(this.variable + this.siteUrlvideo_api, {headers: this.valortoken});
    }

    getVideoDashboard(token): Observable<VideoResponse[]> {
        this.valortoken = new HttpHeaders({
            'Authorization': 'Bearer ' + token
        });
        return this._http.get<VideoResponse[]>(this.variable + this.siteUrlvideoDash, {headers: this.valortoken});
    }

    getNewDashboard(token): Observable<NewResponse[]> {
        this.valortoken = new HttpHeaders({
            'Authorization': 'Bearer ' + token
        });
        return this._http.get<NewResponse[]>(this.variable + this.siteUrlnewDash, {headers: this.valortoken});
    }
    getNew(token): Observable<NewResponse[]> {
        this.valortoken = new HttpHeaders({
            'Authorization': 'Bearer ' + token
        });
        return this._http.get<NewResponse[]>(this.variable + this.siteUrlnew, {headers: this.valortoken});
    }
    getCantidadvistas(token): Observable<VistaResponse> {
        this.valortoken = new HttpHeaders({
            'Authorization': 'Bearer ' + token
        });
        return this._http.get<VistaResponse>(this.variable + this.siteUrvistas, {headers: this.valortoken});
    }

    getCarrusel(token): Observable<CarruselResponse[]> {
        this.valortoken = new HttpHeaders({
            'Authorization': 'Bearer ' + token
        });
        return this._http.get<CarruselResponse[]>(this.variable + this.siteUrlcarrusel_api, {headers: this.valortoken});
    }

    getPprivacidad(token): Observable<PprivacidadResponse[]> {
        this.valortoken = new HttpHeaders({
            'Authorization': 'Bearer ' + token
        });
        return this._http.get<PprivacidadResponse[]>(this.variable + this.siteUrlpprivacidad_api, {headers: this.valortoken});
    }

    postSuscrib(token, correo, region): Observable<SuscribeResponse> {
        this.valortoken = new HttpHeaders({
            'Authorization': 'Bearer ' + token
        });
        return this._http.post<SuscribeResponse>(this.variable + this.siteUrlsuscripcion_api, {
            'correo': correo,
            'region': region
        }, {headers: this.valortoken})
    }
}
