import {Component, ElementRef, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {animate, query, stagger, style, transition, trigger} from '@angular/animations';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ArtistService} from '../../services/artist.service';
import {Subscriber} from 'rxjs';
import {SuscribeResponse} from '../../interfaces/artist-interface';

@Component({
    selector: 'app-shop',
    templateUrl: './shop.component.html',
    styleUrls: ['./shop.component.css']
})
export class ShopComponent implements OnInit {
    checkoutForm: FormGroup
    showAlertNotification = false;
    showAlertNotificationexist = false;
    showAlertNotificationerror = false;
    showAlertNotificationcomplete = false;

    // ESTO ES LO QUE AÑADI DE PRUEBA pero tengo que ponerlo generico
    public selectedLanguage = 'en';
    public formcompleted = '';
    public formcompletedbool = true;
    public head: string;


    private token: string;

    constructor(public location: Location, private element: ElementRef, private aboutservice: ArtistService,
                private translate: TranslateService
    ) {
        this.checkoutForm = new FormGroup({
            correo: new FormControl('', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]),
            region: new FormControl('', [Validators.required])
        });

    }

    ngOnInit() {
        this.formcompleted = localStorage.getItem('subscr');
        if (this.formcompleted === 'completed') {
            this.formcompletedbool = false
        }
        this.selectedLanguage = localStorage.getItem('lang');
        this.translate.get('home.head').subscribe(res => this.head = res);

        if (localStorage.getItem('lang') == null) {
            localStorage.setItem('lang', 'en')
        }

        if (localStorage.getItem('lang') != null) {
            this.translate.setDefaultLang(localStorage.getItem('lang'));
        } else {
            localStorage.setItem('lang', this.selectedLanguage)
            this.translate.setDefaultLang('en');
        }
        this.selectedLanguage = localStorage.getItem('lang');
    }

    onSubmit(): void {
        let correo;
        let region;
        if (this.checkoutForm.valid) {
            correo = this.checkoutForm.value.correo
            region = this.checkoutForm.value.region
            this.aboutservice.getToken().subscribe(algo => {
                this.token = algo.access_token;
                this.aboutservice.postSuscrib(this.token, correo, region).subscribe((response: any) => {
                    console.log(response.status);
                    if (response.status === 'success') {
                        this.showAlertNotification = true;
                        this.checkoutForm.value.correo = ''
                        this.checkoutForm.value.region = 0
                        localStorage.setItem('subscr', 'completed');
                        window.location.reload()
                    } if (response.status === 'error') {
                        this.showAlertNotificationexist = true;
                        this.checkoutForm.value.correo = ''
                        this.checkoutForm.value.region = 0
                    } else {
                        this.showAlertNotificationerror = true;
                        this.checkoutForm.value.correo = ''
                        this.checkoutForm.value.region = 0
                    }
                });
            })
        } else {
            this.showAlertNotificationcomplete = true;
            this.checkoutForm.value.correo = ''
            this.checkoutForm.value.region = 0
        }
    }
}
