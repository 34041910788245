import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import { ShopComponent } from '../admin/components/shop/shop.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {InstagramDOMService} from './instagram-dom.service';




@NgModule({
    declarations: [
        DashboardComponent,
        ShopComponent,
    ],
    imports: [
        CommonModule,
        DashboardRoutingModule,
        NgbModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    exports: [
    ],
    providers:    [ InstagramDOMService ],
})
export class DashboardModule { }
