import {Pipe, PipeTransform} from '@angular/core';
import {ArtistResponse, VideoResponse} from '../../interfaces/artist-interface';

@Pipe({
    name: 'filtervideospipe'
})
export class FilterPipeVideos implements PipeTransform {
    transform(videos: VideoResponse[], filtervideo: string) {
        if (videos.length === 0 || filtervideo === '') {
            return videos;
        } else {
            return videos.filter((artist) => {
                return artist.nombre.toLowerCase().includes(filtervideo.toLowerCase())
            })
        }

    }
}
