import { Component, OnInit, ViewChild } from '@angular/core';
import {ArtistResponse, CarruselResponse, NewResponse, PaginaResponse, VideoResponse} from '../admin/interfaces/artist-interface';
import { ArtistService } from '../admin/services/artist.service';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbCarousel, NgbCarouselConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { InstagramDOMService } from './instagram-dom.service';
import { not } from '@angular/compiler/src/output/output_ast';



@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css'],
    animations: [
        trigger('listAnimation', [
            transition('* <=> *', [
                query(':enter',
                    [style({ opacity: 0 }), stagger('300ms', animate('600ms ease-out', style({ opacity: 1 })))],
                    { optional: true }
                ),
                query(':leave',
                    [style({ opacity: 1 }), stagger('300ms', animate('600ms ease-out', style({ opacity: 0 })))],
                    { optional: true }
                )
            ])
        ])
    ],
})
export class DashboardComponent implements OnInit {
    aboutlist1: SafeResourceUrl;
    aboutlist2: SafeResourceUrl;
    aboutlist3: SafeResourceUrl;

    public safeSrc: SafeResourceUrl;
    public showcaruselartist = true
    public showcaruselvideo = true
    public showcaruselnew = true
    public htmlinstagram: SafeHtml;
    public videoimg: SafeResourceUrl;
    public instagramsa: SafeResourceUrl;
    public playlist: string;
    public count: number;
    public count2: number;
    artistArray: ArtistResponse[];
    artistArray2: ArtistResponse[];
    videoArray: VideoResponse[];
    videoArray2: VideoResponse[];
    newArray: NewResponse[];
    carruselArray: CarruselResponse[];
    paginas_artistas: PaginaResponse[];
    response: any;
    token: any;

    valor: any;

    @ViewChild('carousel', { static: true }) carousel: NgbCarousel;
    @ViewChild('carousel_artist') carousel_artist: NgbCarousel;
    @ViewChild('carousel_video') carousel_video: NgbCarousel;

    posIni: any;

    move(pos: number) {
        const offset = this.posIni - pos;
        if (offset < -100) {
            this.carousel.prev()
        }

        if (offset > 100) {
            this.carousel.next();
        }
    }

    moveartist(pos: number) {
        const offset = this.posIni - pos;
        if (offset < -100) {
            this.carousel_artist.prev()
        }

        if (offset > 100) {
            this.carousel_artist.next();
        }
    }

    movevideo(pos: number) {
        const offset = this.posIni - pos;
        if (offset < -100) {
            this.carousel_video.prev()
        }

        if (offset > 100) {
            this.carousel_video.next();
        }
    }

    // tslint:disable-next-line:max-line-length
    constructor(private instagram: InstagramDOMService, private aboutservice: ArtistService, config: NgbCarouselConfig, private router: Router, private sanitizer: DomSanitizer, private modalService: NgbModal, private otromodal: NgbModal) {
        this.carruselArray = [];
        this.valor = ' '
        this.count = 1;
        this.count2 = 1;
        this.artistArray = []
        this.artistArray2 = []
        this.videoArray = []
        this.newArray = []
        this.videoArray2 = []
        this.aboutservice.getToken().subscribe(algo => {
            this.token = algo.access_token;
            this.aboutservice.getCarrusel(this.token).subscribe(data => {
                this.response = data;
                this.response.forEach(element => {
                    if (element.video) {
                        this.playlist = element.video.split('/')
                        this.videoimg = this.playlist[4]
                        this.videoimg = 'https://img.youtube.com/vi/' + this.playlist[4] + '/maxresdefault.jpg'
                        this.safeSrc = element.url_video + '?AutoHide=1&showinfo=2'
                        if (typeof this.videoimg === 'string') {
                            this.carruselArray.push({
                                observaciones: element.observaciones,
                                primera_img: element.primera_img.replace('http', 'https'),
                                video_media: element.video_media,
                                video: this.sanitizer.bypassSecurityTrustResourceUrl(element.video),
                                url_imagen_video: this.sanitizer.bypassSecurityTrustResourceUrl(this.videoimg)
                            })
                        }
                    } else {
                        this.carruselArray.push({
                            observaciones: element.observaciones,
                            primera_img: element.primera_img.replace('http', 'https'),
                            video_media: element.video_media,
                            video: element.video
                        })
                    }
                })
            });
            this.aboutservice.getArtistsDashboard(this.token).subscribe(data => {
                this.response = data;
                this.response.forEach(element => {
                    this.valor = element.observaciones.replace(/font-size: 12pt;/g, ' ')
                    this.valor = this.valor.replace(/font-family: Calibri, sans-serif;/g, ' ')
                    if (this.count2 <= 8) {
                        this.artistArray.push({
                            nombre_artistico: element.nombre_artistico,
                            facebook: element.facebook,
                            twitter: element.twitter,
                            spotify: element.spotify,
                            direccion_web: element.direccion_web,
                            instagram: element.instagram,
                            primera_img: element.primera_img.replace('http', 'https'),
                            youtubechannel: element.youtubechannel,
                            applemusic: element.applemusic,
                            descripcion: this.sanitizer.bypassSecurityTrustHtml(this.valor)
                        })
                        this.count2 += 1;
                    } else {
                    }
                    if (this.count2 >= 9 && this.count2 <= 16) {
                        this.artistArray2.push({
                            nombre_artistico: element.nombre_artistico,
                            facebook: element.facebook,
                            twitter: element.twitter,
                            spotify: element.spotify,
                            direccion_web: element.direccion_web,
                            instagram: element.instagram,
                            primera_img: element.primera_img.replace('http', 'https'),
                            youtubechannel: element.youtubechannel,
                            applemusic: element.applemusic,
                            descripcion: this.sanitizer.bypassSecurityTrustHtml(this.valor)
                        })
                        this.count2 += 1;
                    }
                })
            });
            this.aboutservice.getVideoDashboard(this.token).subscribe(data => {
                this.response = data;
                this.response.forEach(element => {
                    this.playlist = element.url_video.split('/')
                    this.videoimg = this.playlist[4]
                    this.videoimg = 'https://img.youtube.com/vi/' + this.playlist[4] + '/' + element.img_sd + '.jpg'
                    this.safeSrc = element.url_video + '?AutoHide=1&showinfo=2&autoplay=1'
                    if (typeof this.safeSrc === 'string' && typeof this.videoimg === 'string') {
                        if (this.count <= 6) {
                            this.videoArray.push({
                                nombre: element.nombre,
                                url_video: this.sanitizer.bypassSecurityTrustResourceUrl(this.safeSrc),
                                url_imagen_video: this.sanitizer.bypassSecurityTrustResourceUrl(this.videoimg)
                            })
                            this.count += 1;
                        } else if (this.count >= 7 && this.count <= 12) {
                            this.videoArray2.push({
                                nombre: element.nombre,
                                url_video: this.sanitizer.bypassSecurityTrustResourceUrl(this.safeSrc),
                                url_imagen_video: this.sanitizer.bypassSecurityTrustResourceUrl(this.videoimg)
                            })
                            this.count += 1;
                        }
                    }
                })
            });
            this.aboutservice.getNewDashboard(this.token).subscribe(data => {
                this.response = data;
                this.response.forEach(element => {
                    if (typeof element.url_instagram === 'string') {
                        this.newArray.push({
                            nombre: element.nombre,
                            url_instagram: this.sanitizer.bypassSecurityTrustResourceUrl(element.url_instagram)
                        })
                    }
                })
            });
        })

    }

    open(content) {
        this.modalService.open(content, { size: 'lg', centered: true });
    }

    openFullscreen(content) {
        this.modalService.open(content, { size: 'xl', centered: true });
    }

    ngOnInit(): void {
        this.instagram.processEmbeddedInstagramPosts();
        this.carousel.pause()
        this.carousel_artist.pause()
        this.carousel_video.pause()
    }
    moreartists(): void {
        this.showcaruselartist = false
        this.aboutservice.getArtists(this.token).subscribe(data => {
            this.response = data;
            console.log(this.artistArray)
            this.response.forEach(element => {
                this.valor = element.observaciones.replace(/font-size: 12pt;/g, ' ')
                this.valor = this.valor.replace(/font-family: Calibri, sans-serif;/g, ' ')
                if (!this.artistArray.some(objeto => objeto.nombre_artistico === element.nombre_artistico)) {
                this.artistArray.push({
                    nombre_artistico: element.nombre_artistico,
                    facebook: element.facebook,
                    twitter: element.twitter,
                    spotify: element.spotify,
                    direccion_web: element.direccion_web,
                    instagram: element.instagram,
                    primera_img: element.primera_img,
                    youtubechannel: element.youtubechannel,
                    applemusic: element.applemusic,
                    descripcion: this.sanitizer.bypassSecurityTrustHtml(this.valor)
                })
        }
            })

        });
    }
    morevideos(): void {
        this.showcaruselvideo = false
        this.aboutservice.getVideo(this.token).subscribe(data => {
            this.response = data;
            this.response.forEach(element => {
                this.playlist = element.url_video.split('/')
                this.videoimg = this.playlist[4]
                this.videoimg = 'http://img.youtube.com/vi/' + this.playlist[4] + '/' + element.img_sd + '.jpg'
                this.safeSrc = element.url_video + '?AutoHide=1&showinfo=2&autoplay=1'
                if (typeof this.safeSrc === 'string' && typeof this.videoimg === 'string') {
                    if (!this.videoArray.some(objeto => objeto.nombre === element.nombre)) {

                        this.videoArray.push({
                            nombre: element.nombre,
                            url_video: this.sanitizer.bypassSecurityTrustResourceUrl(this.safeSrc),
                            url_imagen_video: this.sanitizer.bypassSecurityTrustResourceUrl(this.videoimg)
                        })
                    }
                }
            })
        });
    }
    morenews(): void {
        this.showcaruselnew = false
        this.aboutservice.getNew(this.token).subscribe(data => {
            this.response = data;
            this.response.forEach(element => {
                if (typeof element.url_instagram === 'string') {
                    if (!this.newArray.some(objeto => objeto.nombre === element.nombre)) {
                        this.newArray.push({
                            nombre: element.nombre,
                            url_instagram: this.sanitizer.bypassSecurityTrustResourceUrl(element.url_instagram)
                        })
                    }
                }
            })
        });
    }
}
