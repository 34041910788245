import { Component, OnInit } from '@angular/core';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ArtistResponse, VideoResponse} from '../../interfaces/artist-interface';
import {ArtistService} from '../../services/artist.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {Router} from '@angular/router';
import {animate, query, stagger, style, transition, trigger} from '@angular/animations';
@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.css'],
  animations: [
    trigger('listAnimation', [
      transition('* <=> *', [
        query(':enter',
            [style({ opacity: 0 }), stagger('300ms', animate('600ms ease-out', style({ opacity: 1 })))],
            { optional: true }
        ),
        query(':leave',
            animate('200ms', style({ opacity: 0 })),
            { optional: true}
        )
      ])
    ])
  ]
})
export class VideosComponent implements OnInit {
  public safeSrc: SafeResourceUrl;
  public videoimg: SafeResourceUrl;
  public playlist: string;
  stafflist: string;
  videoArray: VideoResponse[];
  response: any;
  token: any;
  filtervideo: string;
  constructor(private aboutservice: ArtistService, private router: Router, private sanitizer: DomSanitizer, private modalService: NgbModal){
    this.videoArray = []
    this.aboutservice.getToken().subscribe(algo => {
      this.token = algo.access_token;
      this.aboutservice.getVideo(this.token).subscribe(data => {
        this.response = data;
        this.response.forEach(element => {
          this.playlist = element.url_video.split('/')
          this.videoimg = this.playlist[4]
          this.videoimg = 'https://img.youtube.com/vi/' + this.playlist[4] + '/' + element.img_sd + '.jpg'
          this.safeSrc = element.url_video + '?AutoHide=1&showinfo=2&autoplay=1'

          if (typeof this.safeSrc === 'string') {
            if (typeof this.videoimg === 'string') {
              this.videoArray.push({
                nombre: element.nombre + element.artista,
                url_video: this.sanitizer.bypassSecurityTrustResourceUrl(this.safeSrc),
                url_imagen_video: this.sanitizer.bypassSecurityTrustResourceUrl(this.videoimg),
                artista: element.artista
              })
            }
          }
        })
      });
    });
  }

  ngOnInit(): void {
  }
  openFullscreen(content) {
    this.modalService.open(content, { size: 'xl', centered: true });
  }
}
